<template>
	<div>
		<div class="work-together-container">
			<div class="sanity-container">
				<h1 class="work-together-title">
					<p style="margin: 0px; line-height: 1">Blog</p>
				</h1>
				<p
					class="work-together-paragraph togetherTextActive"
					data-v-5f82028e=""
				></p>
			</div>
			<div class="programmes-arrow">
				<img class="arrow-svg" src="../assets/arrow.svg" /><span
					class="arrow-circle"
				></span>
			</div>
		</div>

		<div class="talkseries what-we-do-section-container" v-if="isBlogPage">
			<div
				v-for="post in posts"
				:key="post._id"
				class="what-we-do-section-container blog-post flex sm-header"
			>
				<div class="align-center">
					<h1 ref="title" class="title-small">
						<router-link :to="`/blog/${post.slug.current}`">{{
							post.title
						}}</router-link>
					</h1>
					<p v-if="post.excerpt" class="paragraph" ref="paragraph">
						{{ post.excerpt }}
					</p>

					<router-link :to="`/blog/${post.slug.current}`">
						<button class="what-we-do-btn">
							<h3>Read more</h3>
						</button></router-link
					>
				</div>

				<div class="picture-container" v-if="post.featuredImage?.asset">
					<router-link :to="`/blog/${post.slug.current}`"
						><img
							:src="post.featuredImage.asset.url"
							:alt="post.title"
							class="what-we-do-pic featured-image"
					/></router-link>
				</div>
			</div>

			<!-- Paginazione -->
			<div class="pagination">
				<router-link
					v-if="currentPage > 1"
					:to="
						currentPage === 2
							? `/blog`
							: `/blog/page/${currentPage - 1}`
					"
					class="pagination-link"
				>
					Previous
				</router-link>
				<router-link
					v-if="posts.length === postsPerPage"
					:to="`/blog/page/${currentPage + 1}`"
					class="pagination-link"
				>
					Next
				</router-link>
			</div>
		</div>

		<div v-if="$route.fullPath !== '/work-together'">
			<WorkTogether />
		</div>
	</div>
</template>

<script>
import sanity from "../client";
import WorkTogether from "../components/WorkTogether.vue";

// Funzione per creare una query con limit e offset
function getBlogPostsQuery(limit, offset) {
	return `*[_type == "blog"] | order(_createdAt desc) [${offset}...${
		offset + limit
	}] {
		_id,
		title,
		description,
		excerpt,
		slug,
		featuredImage {
			asset->{
				_id,
				url
			}
		}
	}`;
}

export default {
	name: "Blog",
	components: {
		WorkTogether
	},
	props: {
		page: {
			type: Number,
			default: 1
		}
	},
	data() {
		return {
			loading: true,
			posts: [],
			isBlogPage: true,
			postsPerPage: 10,
			currentPage: parseInt(this.$route.params.page) || 1
		};
	},
	created() {
		this.checkIfBlogPage();
		this.fetchBlogPosts();
	},
	watch: {
		"$route.params.page"(newPage) {
			this.currentPage = parseInt(newPage) || 1;
			this.fetchBlogPosts();
		}
	},
	methods: {
		checkIfBlogPage() {
			if (this.$route.fullPath.startsWith("/blog")) {
				this.isBlogPage = true;
			} else {
				this.isBlogPage = false;
			}
		},
		async fetchBlogPosts() {
			try {
				const offset = (this.currentPage - 1) * this.postsPerPage;
				const query = getBlogPostsQuery(this.postsPerPage, offset);
				const posts = await sanity.fetch(query);
				this.posts = posts;
			} catch (err) {
				console.error("Error fetching blog posts:", err);
			}
		}
	}
};
</script>

<style scoped>
.what-we-do-section-container {
	padding-top: 8vw;
	padding-bottom: 10vw;

	width: 100vw;
	background: #f7f5f2;
	margin-top: 0;
}
.what-we-do-section-container.blog-post {
	padding-top: 0;
	padding-bottom: 7vw;
	flex-direction: row;
}
.what-we-do-section-container.blog-post:nth-child(2n + 2) {
	flex-direction: row-reverse;
}
.align-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.featured-image {
	width: 100%;
	height: auto;
	object-fit: cover;
	margin-bottom: 1rem;
}

.pagination {
	margin-top: 2rem;
	display: flex;
	justify-content: center;
	gap: 1.2vw;
}

.pagination-link {
	position: relative;
	left: 0;
	width: 8vw;
	font-family: Arial, Helvetica, sans-serif;
	height: 3vw;
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #544a3f;
	text-transform: uppercase;
	font-size: 1vw;
	background: none;
	border: 0.2vw solid #d4c09e;
	cursor: pointer;
}

.pagination-link:hover {
}

.no-bg {
	background: transparent;
}

.git {
	position: relative;
	margin-top: 2.5vw;
	text-align: left;
	-webkit-transition: 1s;
	transition: 1s;
	font-size: 1.3vw;
	float: left;
	opacity: 1;
	font-weight: 700;
	cursor: pointer;
	font-family: DM sans;
	color: #544a3f;
	-webkit-text-decoration-color: #d4c09e;
	text-decoration-color: #d4c09e;
	text-decoration: underline;
}
.title {
	position: relative;
	text-align: left;
	/*margin-left: 11vw;*/
	line-height: 5.8vw;
	font-size: 6.25vw;
	width: 35vw;
	/*margin-top: 2vw;*/
	opacity: 1;
}
.title-small {
	position: relative;
	text-align: left !important;
	width: 88vw;
	display: inline-block;
	opacity: 1;
	margin-bottom: 0.67em;
	margin-top: 0;
}

@media (min-aspect-ratio: 200/200) {
	.title-small {
		position: relative;
		text-align: left;
		font-size: 3vw;
		width: 35vw;
		display: inline-block;
		opacity: 1;
		margin-bottom: 0.67em;
	}
}

.sm-header.flex-image-left .picture-container {
	width: 45vw;
}

@media (max-aspect-ratio: 200/200) {
	.sm-header.flex-image-left .picture-container {
		width: 88vw;
	}
}

.flex {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

@media (max-aspect-ratio: 200/200) {
	.flex {
		display: contents;
	}
	.title-small {
		margin-top: 0.67em;
		margin-left: 6vw;
	}
}

.flex-edge-standard {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.flex-edge-standard {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.flex-edge-standard .title {
	width: 43vw;
}

@media (max-aspect-ratio: 200/200) {
	.flex-edge-standard {
		display: contents;
	}

	.flex-edge-standard a {
		font-size: 4vw;
		left: 22px;
	}
}

.flex-edge-standard .title {
	width: 43vw;
}

.flex-edge-standard div:first-child {
	margin-right: 10vw;
}

.flex-image-left {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-evenly;
	align-items: center;
}

@media (max-aspect-ratio: 200/200) {
	.flex-image-left {
		display: contents;
	}
	.flex-edge-standard .title {
		width: 67vw;
	}
}

.flex-edge-left {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	align-items: center;
}

.flex-edge-left div:first-child {
	margin-left: 10vw;
}

.flex-edge-left .picture-container {
	height: 40vw;
}

.flex-edge-left .picture-containr img {
	height: 40vw;
	object-fit: cover;
}

.flex-edge-standard .picture-container {
	height: 40vw;
}

.flex-edge-standard .picture-container img {
	height: 40vw;
	object-fit: cover;
}

.picture-container {
	position: relative;
	height: 25vw;
	min-width: 34.5vw;
	width: 35vw;
	/*overflow: hidden;*/
}
.picture-container img {
	object-fit: cover;
	width: 100%;
	display: block;
}
.what-we-do-pic {
	width: 100%;
	position: relative;
}
.what-we-do-section-container img {
	height: 100%;
	object-fit: cover;
}
.paragraph {
	position: relative;
	margin-top: -2.5vw;
	text-align: left;
	/*margin-left: 11vw;*/
	transition: 1s;
	font-size: 1.3vw;
	width: 35vw;
	opacity: 1;
	font-family: DM sans;
}
.blog-post p.paragraph {
	margin-top: 0;
	margin-bottom: 3vw;
}

.what-we-do-btn {
	position: relative;
	left: 0;
	margin-top: 1vw;
	width: 18vw;
	height: 4.5vw;
	display: block;
	text-transform: uppercase;
	background: none;
	border: 0.2vw solid #d4c09e;
	cursor: pointer;
}

.what-we-do-btn h3 {
	font-size: 0.9vw;
	color: #544a3f;
	padding: 0.45vw;
}

.carousel-container {
	width: 100%;
	margin-top: 0px;
}

.slick-carousel {
	position: relative;
	margin: 0 3.8vw;
}

.carousel-slide {
	position: relative;
	text-align: center;
}

.carousel-image {
	width: 100%;
	min-height: 30vw;
	max-height: 30vw;
	object-fit: cover;
}
.slick-dots li.slick-active button:before {
	opacity: 0.75 !important;
	color: #544a3f !important;
}
.slick-dots li button:before {
	border: 1px solid #544a3f !important;
	border-radius: 50% !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

@media (max-aspect-ratio: 200/200) {
	.what-we-do-section-container.blog-post.flex.sm-header {
		display: flex !important;
		flex-direction: column-reverse !important;
	}
	.pagination-link[data-v-781941dc] {
		width: 23vw;
		height: 8vw;
		font-size: 2.5vw;
		border: 0.5vw solid #d4c09e;
	}
	.flex-edge-standard .picture-container {
		height: 100vw;
	}

	.flex-edge-standard .picture-container img {
		height: 100vw;
	}

	.title {
		margin-left: 6vw;
		line-height: 10vw;

		font-size: 11.25vw;
		width: 90vw;
		margin-top: 2vw;
	}
	.paragraph {
		text-align: left;
		font-size: 3.8vw;
		width: 88vw;
		margin-left: 6vw;
	}
	.picture-container {
		margin-left: 0;
		left: 0;
		margin-top: 10vw;
		width: 100vw;
		height: 80vw;
	}
	.carousel-container img {
		min-height: 78vw;
		max-height: 78vw;
	}
	.what-we-do-btn {
		margin-top: 3vw;
		margin-left: 6vw;
		width: 50vw;
		height: 15vw;

		border: 0.5vw solid #d4c09e;
	}

	.what-we-do-btn h3 {
		font-size: 2.5vw;
		color: #544a3f;
		padding: 0.45vw;
	}
	.what-we-do-section-container {
		padding-bottom: 6vw;
	}
	.blog-post p.paragraph {
		margin-bottom: 8vw;
	}
	.carousel {
		margin-top: 2vw !important;
	}
	.carousel-container {
		width: 100%;
		margin-top: 5vw;
	}
	.what-we-do-section-container.blog-post {
		flex-direction: row-reverse !important;
	}
}
</style>

<style scoped>
.work-together-container {
	position: relative;
	width: 100vw;
	padding-top: 10vw;
	padding-bottom: 20vw;
	opacity: 1;
	margin-top: -15vw;
}

@media (max-aspect-ratio: 200/200) {
	.work-together-container {
		padding-top: 20vw;
		padding-bottom: 20vw;
	}
}

.work-together-title {
	position: relative;
	left: 30%;
	transform: translateX(-50%);
	width: 16vw;
	top: 2vw;
	text-align: right;
	font-size: 6.25vw;
	line-height: 5.75vw;
	transition: 1s;
	text-transform: uppercase;
}

@media (min-aspect-ratio: 200/200) {
	.work-together-title p {
		left: -12vw !important;
		position: relative;
	}
}

.work-together-paragraph {
	position: relative;
	left: 60%;
	transform: translateX(-50%);
	width: 40vw;
	text-align: left;
	margin-top: -1vw;
	font-size: 1.3vw;
	opacity: 0;
	font-family: DM sans;
	transition: 1s;
}

.togetherTextActive {
	opacity: 1;
}

.work-together-button {
	position: relative;
	left: 46%;
	transform: translateX(-50%);
	margin-top: 3vw;
	width: 12vw;
	height: 4.5vw;
	display: block;

	background: none;
	border: 0.2vw solid #d4c09e;
	cursor: pointer;
}

.work-together-button h3 {
	font-size: 0.9vw;
	color: #544a3f;
	padding: 0.45vw;
}

.social-contact-info {
	position: relative;
	left: 49%;
	transform: translateX(-50%);
	margin-top: 4vw;
	width: 18vw;
	height: 3.8vw;
}

.icon-container {
	position: absolute;
	left: 0;
	top: 0;
	height: 3.8vw;
	width: 3.8vw;
	border: 0.2vw solid #d4c09e;
	border-radius: 50%;
}

.info-social-title {
	position: relative;
	left: 5.5vw;
	padding-top: 0.5vw;
	text-transform: uppercase;
	font-family: DM Sans;

	font-size: 0.8vw;
	text-align: left;
}

.info-social-contact {
	position: relative;
	left: 5.5vw;
	margin-top: -0.5vw;

	font-family: DM Sans;

	font-size: 1.4vw;
	text-align: left;
	white-space: nowrap;
}

.email-contact {
	margin-top: 5vw;
}

.socials-img {
	position: relative;
	left: 50%;
	top: 50%;
	width: 50%;
	display: block;
	transform: translate(-50%, -50%);
}

@media (max-aspect-ratio: 200/200) {
	.work-together-container {
		margin-top: -10vw;

		padding-top: 20vw;
		padding-bottom: 20vw;
	}
	.work-together-title {
		font-size: 11.25vw;
		line-height: 10.75vw;
		width: 80%;
		text-align: center;
		left: 50%;
	}

	.work-together-paragraph {
		font-size: 3.8vw;
		width: 80%;
		text-align: center;
		left: 50%;
	}

	.work-together-button {
		margin-top: 8vw;
		left: 50%;
		width: 35vw;
		height: 15vw;

		border: 0.5vw solid #d4c09e;
	}

	.work-together-button h3 {
		font-size: 2.5vw;
		color: #544a3f;
		padding: 0.45vw;
	}

	.social-contact-info {
		position: relative;
		left: 49%;
		transform: translateX(-50%);
		margin-top: 8vw;
		width: 60vw;
		height: 34vw;
	}

	.email-contact {
		margin-top: 15vw;
	}

	.icon-container {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 0;
		height: 14.8vw;
		width: 14.8vw;
		border: 0.5vw solid #d4c09e;
		border-radius: 50%;
	}

	.info-social-title {
		position: relative;

		padding-top: 19.5vw;
		text-transform: uppercase;

		left: 0;
		font-size: 3vw;
		text-align: center;
	}

	.info-social-contact {
		position: relative;
		left: 0;
		margin-top: -0.5vw;

		font-family: DM Sans;

		font-size: 4.5vw;
		text-align: center;
		white-space: nowrap;
	}
}
a {
	color: inherit;
}

.programmes-arrow {
	position: absolute;
	cursor: pointer;
	height: 4vw;
	width: 3vw;
	left: 50%;
	transform: translateX(-50%);
	margin-top: 2vw;
}

.arrow-svg {
	position: absolute;
	bottom: 0.9vw;
	left: 50%;
	width: 1.2vw;
	transform: translateX(-50%);
	z-index: +1;
}

.arrow-circle {
	position: absolute;
	bottom: 0;
	border: 0.3vw solid #e1d6c2;
	height: 2.5vw;
	width: 2.5vw;
	border-radius: 50%;
	left: 50%;
	transform: translateX(-50%);
}

@media (max-aspect-ratio: 200/200) {
	.programmes-arrow {
		margin-top: 10vw;
	}
	.arrow-svg {
		bottom: 2.2vw;
		width: 3vw;
	}

	.arrow-circle {
		border: 0.8vw solid #e1d6c2;
		height: 6vw;
		width: 6vw;
	}

	/* .work-together-container {
		margin-top: -0vw;
	} */
}
</style>
